import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { PageProps } from "../shared";
import { green, navy } from "../shared/colors";
import Responsive, { small } from "../components/responsive";

const Container = styled.div`
    display: flex;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    ${Responsive};
`;

const Testimonial = styled.div`
    background: white;
    flex: 0 0 250px;
    margin: 10px;
    padding: 10px;
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    transition: all ease-in 0.3s;
    cursor: pointer;

    > p {
        margin-top: 0;
        flex: 1;
        overflow: auto;
        text-overflow: ellipsis;
        color: white;
        z-index: 3;
        font-weight: 500;
    }

    &:hover {
        box-shadow: 0 0 5px 5px rgba(33, 33, 71, 0.2);
    }
`;
const Who = styled.div`
    color: ${green};
    z-index: 3;
    display: flex;
    justify-content: end;
`;

const BackgroundImage = styled.div<{ backgroundUrl: string }>`
    background: url(${props => props.backgroundUrl});
    background-attachment: fixed;
    background-repeat: no-repeat;  
    background-size: cover;
    background-position: center 150px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    border-radius: 5px;
`;

const BackgroundCover = styled.div`
    background: rgba(33, 33, 71, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: 5px;
`;
const ActiveTestimonial = styled.div<{ show: boolean }>`
    position: relative;
    background: white;
    transition: all 0.3s ease-in-out;
    transform: ${props => props.show ? 'translateY(0%)' : 'translateY(-100%)'};
    margin: 15% auto auto auto;
    width: 50%;
    border-radius: 10px;
`;
const ActiveTestimonialBody = styled.p`
    padding: 10px;
    color: ${navy};
`;
const ActiveTestimonialFooter = styled.div`
    padding: 10px;
    font-weight: 500;
    color: ${navy};
    display: flex;
    justify-content: end;
`;
const ActiveTestimonialContainer = styled.div<{ show: boolean }>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 100;
    background: rgba(0,0,0,0.5);
    cursor: pointer;
    ${props => props.show
        ? `
        opacity: 1;
        z-index: 100;
        transition: z-index 0s, opacity 0.3s ease-in-out;
        `
        : `
        z-index: -1;
        opacity: 0;
        transition: all 0.3s ease-in;
    `}
`;

const Featured = styled.div`
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    min-width: 100%;
    border-radius: 5px;
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    transition: all ease-in 0.3s;

    &:hover {
        box-shadow: 0 0 5px 5px rgba(33, 33, 71, 0.2);
    }
`;

const FeaturedVideo = styled.div`
    overflow: hidden;
    padding-bottom: 35.25%;
    position: relative;
    height: 0;
    flex: 2;
    margin: 10px 10px 10px 20px;

    @media(max-width: ${small}) {
        margin: 10px;
        min-width: 300px;
    }

    > iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
`;

const FeaturedText = styled.div`
    flex: 1;
    margin: 10px 20px 10px 10px;
    min-width: 100px;
`;

const VideoBuffer = styled.div`
    min-width: 100%;

    > div {
        height: 2px;
        content: '';
        background: ${green};
        display: block;
        width: 100px;
        margin: 20px auto;   
    } 
`;


type Testimonial = { who: string, review: string };
type TestimonialsProps = { headerImg: string, image: string, testimonials: Testimonial[], featuredVid: string, featuredText: string };

type TestimonialsState = {
    activeTestimonial: Testimonial | null,
    show: boolean
};

export const query = graphql`
{
  pageData: testimonialsJson {
    testimonials {
      review
      who
    }
    image
    headerImg
    featuredVid
    featuredText
  }
}
`;

class Testimonials extends React.Component<PageProps<TestimonialsProps>, TestimonialsState> {
    constructor(props: PageProps<TestimonialsProps>) {
        super(props);
        this.state = {
            activeTestimonial: null,
            show: false
        }
        this.showTestimonial = this.showTestimonial.bind(this);
        this.dismiss = this.dismiss.bind(this);
    }

    showTestimonial = (testimonial: Testimonial) => {
        this.setState({
            activeTestimonial: testimonial,
            show: true
        });
    }

    dismiss() {
        this.setState({
            show: false
        });
        setTimeout(() => {
            this.setState({
                activeTestimonial: null
            });
        }, 300)
    }

    render() {
        const { image, testimonials, headerImg, featuredVid, featuredText } = this.props.data.pageData;
        const { activeTestimonial, show } = this.state;
        return (<>
            <Layout header={headerImg} title="Testimonials" transparent={true}>
                <Seo title="Testimonials" />
                <Container>
                    <Featured>
                        <FeaturedVideo>
                            <iframe
                                width="853"
                                height="480"
                                src={featuredVid}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                            />
                        </FeaturedVideo>
                        <FeaturedText>{featuredText}</FeaturedText>
                    </Featured>
                    <VideoBuffer>
                        <div></div>
                    </VideoBuffer>
                    {testimonials.map((t, index) => (
                        <Testimonial key={index} onClick={() => this.showTestimonial(t)}>
                            <p>{t.review}</p>
                            <Who><span>{t.who}</span></Who>


                            <BackgroundImage backgroundUrl={image} ></BackgroundImage>
                            <BackgroundCover></BackgroundCover>
                        </Testimonial>
                    ))}
                </Container>
            </Layout>


            <ActiveTestimonialContainer show={show} onClick={this.dismiss}>
                <ActiveTestimonial show={show}>
                    <ActiveTestimonialBody>{activeTestimonial?.review}</ActiveTestimonialBody>
                    <ActiveTestimonialFooter>{activeTestimonial?.who}</ActiveTestimonialFooter>
                </ActiveTestimonial>
            </ActiveTestimonialContainer>
        </>
        );

    }
}

export default Testimonials;

